.sliderTitle{
    height: 15%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    animation: animate 3s linear normal, title 5s linear infinite;
    border-radius: 5px;
}
.slide{
    object-fit: cover;
    animation: background 5s linear infinite;
    background: url("") no-repeat right center fixed;
    transition: background-image 1s ease-in-out;
}

.slideArea{
    height: 70vh;
    overflow: hidden;
    border-bottom: 1px solid #000000;
}
.scrool{
    height: 7px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #0599DB;
    z-index: 1;
    animation: scrool 20s linear infinite;
}
@keyframes title {
    0%{
        height: 15%;
    }
    50%{
        height: 17%;
        
    }
    100%{
        height: 15%;
        
    }
    
}
@media screen and (max-width: 768px){
    .slideArea{
        height: 30vh;
    }
    .sliderTitle{
        height: 30%;
    }
    @keyframes title {
        0%{
            height: 30%;
        }
        50%{
            height: 32%;
            
        }
        100%{
            height: 30%;
            
        }
        
    }
}
    
@keyframes scrool {
    0%{
        width: 0%;
    }
    50%{
        width: 100%;
    }
    100%{
        width: 0%;
    }
    
}
@keyframes background {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.01);
    }
    100%{
        transform: scale(1);
    }
    
}



@keyframes animate {
    0%{
        left: -50%;
    }
    20%{
        left: -50%;
    }
    45%{
        left: 60%;
    }
    75%{
        left: 40%;
    }
    100%{
        left: 50%;
    }
    
}

.loginPage {
    .fullscreen-container {
      height: 100vh;
      width: 100vw;
  
      background-color: #282828;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .container {
      width: 100vw;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 10px;
    }
  
    .heading {
      width: 100%;
      text-align: center;
      font-size: 4rem;
      p{
      font-size: 3rem;
        margin-top: 20px;
      }
    }
  
    .heading span {
      font-size: 4rem;
    }
  
    .form-container {
      border-radius: 10px;
      background: #212121;
      padding: 50px;
      color: white;
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      max-width: 400px;
      border: 1px solid;
    }
  
    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  
    .input-group {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  
    .input-group label {
      font-size: 0.9rem;
      letter-spacing: 1px;
    }
  
    .input-group input {
      border-radius: 5px;
      border: none;
      outline: none;
      padding: 7px;
      font-size: 1rem;
      width: 100%;
    }
  
    .input-group input:focus {
      box-shadow: 0 0 0 3px #0599DB;
    }
  
    button {
      border-radius: 5px;
      padding: 10px;
      margin-top: 20px;
      outline: none;
      border: none;
      font-size: 1rem;
      background: #0599DB;
      color: white;
      cursor: pointer;
    }
  
    button:focus,
    button:visited {
      opacity: 0.8;
    }
    button:hover {
      transform: scale(1.05);
    }
  
    @media (max-width: 700px) {
      .container {
        flex-direction: column;
        gap: 25px;
      }
    }
  }
  .itemFooter {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    align-items: center;
    .left {
      margin: 0 10px;
      text-align: left;
    }
    .right {
      margin: 010px;
      button {
        padding: 10px 20px;
        background-color: #ba0000;
      }
    }
  }
  .PanelLogo {
    padding-top: 14px;
    margin-left: 23px;
    background-color: rgb(234, 0, 0);
    width: 80px;
    height: 80px;
    z-index: 99;
  }
  
// header

  .section{
    &.padding_layout_1{
        .container{
            .row{
                &:last-child{
                    margin-top: 35px;
                    @media screen and (max-width: 768px){
                        margin-top: 0px;
                    }
                    }
                }
            }
        }
    }

    // loading
    .loader_bg {
      position: fixed;
      z-index: 9999999;
      background: rgb(237, 237, 237);
      background: linear-gradient(
        153deg,
        rgba(237, 237, 237, 1) 68%,
        rgba(221, 221, 221, 1) 100%
      );
      width: 100%;
      height: 100%;
    }
    
    .loader {
      background-color: #000000;
      transition: 3s;
      z-index: 999999;
      opacity: 0;
      height: 100%;
      width: 100%;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      animation: opacity 4s ease-in-out;
      animation-iteration-count: 1;
    }
    @keyframes opacity {
      0% {
        opacity: 1;
      }
      80% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    
    .loader img {
      width: 200px;
      opacity: 0;
      animation: 1s bounce ease infinite, opacity 3.5s ease-in-out 1;
    }
    #hazırlanıyor {
      border-radius: 20px;
      font-size: 30px;
      color: #fff;
      animation: 1s bounce ease infinite;
    }
    .loader .shadow {
      opacity: 0.3;
      width: 240px;
      height: 20px;
      background-color: black;
      box-shadow: 1px 1px 100px black;
    
      border-radius: 50%;
      animation: bouncedelay 1s infinite ease-in-out;
    }
    @keyframes bouncedelay {
      0%,
      80%,
      100% {
        transform: scale(1);
        -webkit-transform: scale(1);
      }
    
      40% {
        transform: scale(0);
        -webkit-transform: scale(0);
      }
    }
    @keyframes bounce {
      50% {
        transform: translateY(-20px);
      }
    }
    //admin page
.admin{
  .warn {
    color: red;
  }
  
  /* header */
  header[role="banner"] {
    background: white;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  }
  header[role="banner"] h1 {
    margin: 0;
    font-weight: 300;
    padding: 1rem;
  }
  header[role="banner"] h1:before {
    content: "\f248";
    font-family: FontAwesome;
    padding-right: 0.6em;
    color: red;
  }
  header[role="banner"] .utilities {
    width: 100%;
    background: slategray;
    color: #ddd;
  }
  header[role="banner"] .utilities li {
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  }
  header[role="banner"] .utilities li a {
    padding: 0.7em;
    display: block;
  }
  
  /* header */
  .utilities a:before {
    content: "\f248";
    font-family: FontAwesome;
    padding-right: 0.6em;
  }
  
  .logout a:before {
    content: "";
  }
  
  .users a:before {
    content: "";
  }
  
  nav[role="navigation"] {
    background: #2a3542;
    color: #ddd;
    /* icons */
  }
  nav[role="navigation"] li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  }
  nav[role="navigation"] li a {
    color: #ddd;
    text-decoration: none;
    display: block;
    padding: 20px;
    cursor: pointer;
  }
  nav[role="navigation"] li a:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
  nav[role="navigation"] li a:before {
    content: "\f248";
    font-family: FontAwesome;
    padding-right: 0.6em;
  }
  nav[role="navigation"] .dashboard a:before {
    content: "";
  }
  nav[role="navigation"] .write a:before {
    content: "";
  }
  nav[role="navigation"] .edit a:before {
    content: "";
  }
  nav[role="navigation"] .comments a:before {
    content: "";
  }
  nav[role="navigation"] .users a:before {
    content: "";
  }
  
  /* current nav item */
  .current, .dashboard .dashboard a,
  .write .write a,
  .edit .edit a,
  .comments .comments a,
  .users .users a {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  footer[role="contentinfo"] {
    background: slategray;
    color: #ddd;
    font-size: 0.8em;
    text-align: center;
    padding: 0.2em;
  }
  
  /* panels */
  .panel {
    background-color: white;
    color: darkslategray;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    border-radius: 0.3rem;
    margin: 1%;
  }
  .panel > h2, .panel > ul {
    margin: 1rem;
  }
  
  /* typography */
  a {
    text-decoration: none;
    color: inherit;
  }
  
  h2,
  h3,
  h4 {
    font-weight: 300;
    margin: 0;
  }
  
  h2 {
    color: #ff1a1a;
  }
  
  b {
    color: lightsalmon;
  }
  
  .hint {
    color: lightslategray;
  }
  
  /* lists */
  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  main li {
    position: relative;
    padding-left: 1.2em;
    margin: 0.5em 0;
  }
  main li {
    position: relative;
    padding-left: 1.2em;
    margin: 0.5em 0;
  }
  main li:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    top: 0.3em;
    border-left: solid 10px #dde;
    border-top: solid 5px transparent;
    border-bottom: solid 5px transparent;
  }
  
  /* forms */
  form input,
  form textarea,
  form select {
    width: 100%;
    display: block;
    border: solid 1px #dde;
    padding: 0.5em;
  }
  form input:after,
  form textarea:after,
  form select:after {
    content: "";
    display: table;
    clear: both;
  }
  form input[type="checkbox"],
  form input[type="radio"] {
    display: inline;
    width: auto;
  }
  form label,
  form legend {
    display: block;
    margin: 1em 0 0.5em;
  }
  form input[type="submit"] {
    background: #ff1a1a;
    border: none;
    border-bottom: solid 4px #e60000;
    padding: 0.7em 3em;
    margin: 1em 0;
    color: white;
    text-shadow: 0 -1px 0 #e60000;
    font-size: 1.1em;
    font-weight: bold;
    display: inline-block;
    width: auto;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    border-radius: 0.5em;
  }
  form input[type="submit"]:hover {
    background: turquoise;
    border: none;
    border-bottom: solid 4px #21ccbb;
    padding: 0.7em 3em;
    margin: 1em 0;
    color: white;
    text-shadow: 0 -1px 0 #21ccbb;
    font-size: 1.1em;
    font-weight: bold;
    display: inline-block;
    width: auto;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    border-radius: 0.5em;
  }
  
  /* feedback */
  .error {
    background-color: #ffe9e0;
    border-color: #ffc4ad;
  }
  
  label.error {
    padding: 0.2em 0.5em;
  }
  
  .feedback {
    background: #fcfae6;
    color: #857a11;
    margin: 1em;
    padding: 0.5em 0.5em 0.5em 2em;
    border: solid 1px khaki;
  }
  .feedback:before {
    content: "";
    font-family: fontawesome;
    color: #e4d232;
    margin-left: -1.5em;
    margin-right: 0.5em;
  }
  .feedback li:before {
    border-left-color: #f6f0b9;
  }
  .feedback.error {
    background: #ffe9e0;
    color: #942a00;
    margin: 1em;
    padding: 0.5em 0.5em 0.5em 2em;
    border: solid 1px lightsalmon;
  }
  .feedback.error:before {
    content: "";
    font-family: fontawesome;
    color: #ff5714;
    margin-left: -1.5em;
    margin-right: 0.5em;
  }
  .feedback.error li:before {
    border-left-color: #ffc4ad;
  }
  .feedback.success {
    background: #98eee6;
    color: #08322e;
    margin: 1em;
    padding: 0.5em 0.5em 0.5em 2em;
    border: solid 1px turquoise;
  }
  .feedback.success:before {
    content: "";
    font-family: fontawesome;
    color: #1aa093;
    margin-left: -1.5em;
    margin-right: 0.5em;
  }
  .feedback.success li:before {
    border-left-color: #6ce7db;
  }
  
  /* tables */
  table {
    border-collapse: collapse;
    width: 96%;
    margin: 2%;
  }
  
  th {
    text-align: left;
    font-weight: 400;
    font-size: 13px;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 10px;
    padding-bottom: 14px;
  }
  
  tr:not(:first-child):hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  td {
    line-height: 40px;
    font-weight: 300;
    padding: 0 10px;
  }
  
  @media screen and (min-width: 600px) {
    html,
    body {
      height: 100%;
    }
  
    header[role="banner"] {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 99;
      height: 75px;
    }
    header[role="banner"] .utilities {
      position: absolute;
      top: 0;
      right: 0;
      background: transparent;
      color: darkslategray;
      width: auto;
    }
    header[role="banner"] .utilities li {
      display: inline-block;
    }
    header[role="banner"] .utilities li a {
      padding: 0.5em 1em;
    }
  
    nav[role="navigation"] {
      position: fixed;
      width: 200px;
      top: 75px;
      bottom: 0px;
      left: 0px;
    }
  
    main[role="main"] {
      margin: 75px 0 40px 200px;
      height: calc(94vh - 75px);
    }
    main[role="main"]:after {
      content: "";
      display: table;
      clear: both;
    }
  
    .panel {
      margin: 2% 0 0 2%;
      float: left;
      width: 96%;
    }
    .panel:after {
      content: "";
      display: table;
      clear: both;
    }
  
    .box, .onethird, .twothirds {
      padding: 1rem;
    }
  
    .onethird {
      width: 33.333%;
      float: left;
    }
  
    .twothirds {
      width: 66%;
      float: left;
    }
  
    footer[role="contentinfo"] {
      clear: both;
      margin-left: 200px;
    }
  }
  @media screen and (min-width: 900px) {
    footer[role="contentinfo"] {
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0px;
      margin: 0;
    }
  
    .panel {
      width: 47%;
      clear: none;
    }
    .panel.important {
      width: 96%;
    }
    .panel.secondary {
      width: 23%;
    }
  }
  
}

.ThemeSlice{
  border-radius: 8px;
  margin: 20px;
  padding: 20px;
  .title{
    font-size: 2rem;
    border-bottom: 2px solid;
    padding: 20px 0;
  }
  .colors{
    margin: 20px 0;
    display: flex;
    width: 100%;
    align-items: flex-end;
    input{
      width: 100px;
      height: 100px;
      margin: 20px 20px 20px 0;
      cursor: pointer;
      &:hover{
        transform: scale(1.1);
      }
    }

    input[type="color"] {
      -webkit-appearance: none;
      border: 1pz solid;

    
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0;
      border-radius: 50%;

    }
    .submit{
      button{
        float: right;
        padding: 10px 20px;
        border-radius: 8px;
        background-color: #2A3542;
        color: white;
        cursor: pointer;
      }
    }
  }
  .submit{
    button{
      float: right;
      padding: 10px 20px;
      border-radius: 8px;
      background-color: #2A3542;
      color: white;
      cursor: pointer;
      margin-bottom: 20px;
    }
  }
  form{
    margin: 20px 0;
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }

    input{
      width: 28%;
      margin: 10px;
      &.description{
        width: 60%;
        height: auto;
        @media screen and (max-width: 700px) {
          width: 100%;
        }
      }
     
      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }
    textarea{
      margin: 10px;
      &.description{
        width: 50%;
        height: auto;
        @media screen and (max-width: 700px) {
          width: 100%;
        }
      }
    }
    label{
      font-weight: bold;
      &::after{
        content: ":";
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
}
.ContactSlice{
  border-radius: 8px;
  margin: 20px;
  padding: 20px;
  .title{
    font-size: 2rem;
    border-bottom: 2px solid;
    padding: 20px 0;
  }

  .submit{
    button{
      float: right;
      padding: 10px 20px;
      border-radius: 8px;
      background-color: #2A3542;
      color: white;
      cursor: pointer;
      margin-bottom: 20px;
    }
  }
  form{
    margin: 20px 0;
    display: flex;
      flex-direction: column;
      justify-content: start;
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }

    input{
      display: inline-block;
      width: 50%;
      margin: 10px;
      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }
    label{
      font-weight: bold;
      display: inline-block;
      &::after{
        content: ":";
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
}
.admin{
  main{
    padding: 0 0 20px 0;
    height: calc(100vh - 75px)
  }
}
@media screen and (max-width: 700px) {
  .logo{
    a{
      float: right;
    }
  }
 
}
@media screen and (max-width: 1000px) {
  .search_icon{
    display: none;
  }
}
.first-ul{
  li{
  
      .active{
        font-weight: bold;
  
    }
  }
}